import { AnimatedSprite, DEG_TO_RAD } from "pixi.js";
export class Tile extends AnimatedSprite {
    constructor(id, gid, tileSet, frames, horizontalFlip = false, verticalFlip = false, diagonalFlip = false) {
        super(frames);
        this.id = id;
        this.gid = gid;
        this.tileSet = tileSet;
        this.frames = frames;
        this.horizontalFlip = horizontalFlip;
        this.verticalFlip = verticalFlip;
        this.diagonalFlip = diagonalFlip;
        this.flip();
    }
    clone(args) {
        return new Tile(this.id, this.gid, this.tileSet, this.frames, args.horizontalFlip ?? this.horizontalFlip, args.verticalFlip ?? this.verticalFlip, args.diagonalFlip ?? this.diagonalFlip);
    }
    flip() {
        if (this.horizontalFlip) {
            this.anchor.x = 1;
            this.scale.x = -1;
        }
        if (this.verticalFlip) {
            this.anchor.y = 1;
            this.scale.y = -1;
        }
        if (this.diagonalFlip) {
            if (this.horizontalFlip) {
                this.anchor.x = 0;
                this.scale.x = 1;
                this.anchor.y = 1;
                this.scale.y = 1;
                this.rotation = DEG_TO_RAD * 90;
            }
            if (this.verticalFlip) {
                this.anchor.x = 1;
                this.scale.x = 1;
                this.anchor.y = 0;
                this.scale.y = 1;
                this.rotation = DEG_TO_RAD * -90;
            }
        }
    }
}
