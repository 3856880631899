import { AnimatedSprite, Point, Rectangle, Texture } from "pixi.js";
export var Direction;
(function (Direction) {
    Direction[Direction["down"] = 0] = "down";
    Direction[Direction["left"] = 1] = "left";
    Direction[Direction["right"] = 2] = "right";
    Direction[Direction["up"] = 3] = "up";
})(Direction || (Direction = {}));
export class Character extends AnimatedSprite {
    static new(args) {
        // Generate four directions of animation textures
        const genTextures = (line) => {
            const textures = [];
            const y = line * args.characterHeight;
            // Right to left
            for (let column = 3; column >= 0; column--) {
                const x = column * args.characterWidth;
                const rect = new Rectangle(x, y, args.characterWidth, args.characterHeight);
                textures.push(new Texture({
                    source: args.baseTexture.source,
                    frame: rect,
                }));
            }
            return textures;
        };
        const animations = {
            [Direction.down]: genTextures(0),
            [Direction.left]: genTextures(1),
            [Direction.right]: genTextures(2),
            [Direction.up]: genTextures(3),
        };
        return new Character(animations, Direction.down, args.map, args.tilePosition);
    }
    constructor(animations, direction, map, tilePosition) {
        super(animations[direction]);
        this.animations = animations;
        this.direction = direction;
        this.map = map;
        this.tilePosition = tilePosition;
        this.movementPath = [];
        this.lastFrame = 0;
        this.needStop = false;
        this.nextTilePos = tilePosition;
        this.animationSpeed = 0.3;
        this.position = this.getActualPosition();
        this.loop = true;
    }
    update(ticker) {
        super.update(ticker);
        // If need stop, stop at frame 0
        if (this.needStop && this.currentFrame == 0) {
            this.stop();
            this.needStop = false;
        }
        // If frame changed
        if (this.playing && this.lastFrame != this.currentFrame) {
            if (this.currentFrame == 0) {
                // Skip frame 0
                this.gotoAndPlay(1);
            }
            // Calculate next tile position
            this.tilePosition.x =
                this.nextTilePos.x -
                    ((this.nextTilePos.x - this.tilePosition.x) / 3) *
                        (3 - this.currentFrame);
            this.tilePosition.y =
                this.nextTilePos.y -
                    ((this.nextTilePos.y - this.tilePosition.y) / 3) *
                        (3 - this.currentFrame);
            // If whole of the animation finished
            if (this.currentFrame >= 3) {
                if (this.movementPath.length > 0) {
                    // Need to move
                    this.prepareMove(this.movementPath.shift());
                }
                else {
                    // Need not to move any more
                    this.needStop = true;
                }
            }
        }
        this.lastFrame = this.currentFrame;
        // Move sprite
        this.position = this.getActualPosition();
    }
    getActualPosition() {
        return new Point(this.tilePosition.x * this.map.data.tilewidth, this.tilePosition.y * this.map.data.tileheight);
    }
    setMovementPath(movementPath) {
        this.movementPath = movementPath;
        if (movementPath.length <= 0) {
            return;
        }
        if (!this.playing) {
            this.prepareMove(movementPath.shift());
            // Start to play animation
            this.gotoAndPlay(1);
        }
    }
    prepareMove(nextTilePos) {
        const nextDirection = this.getNextDirection(nextTilePos[0], nextTilePos[1]);
        if (this.setDirection(nextDirection)) {
            this.gotoAndPlay(1);
        }
        this.nextTilePos = new Point(nextTilePos[0], nextTilePos[1]);
    }
    setDirection(direction) {
        if (direction !== this.direction) {
            this.textures = this.animations[(this.direction = direction)];
            return true;
        }
        return false;
    }
    getNextDirection(nextTileX, nextTileY) {
        let nextDirection = this.direction;
        if (nextTileX > this.tilePosition.x) {
            nextDirection = Direction.right;
        }
        else if (nextTileX < this.tilePosition.x) {
            nextDirection = Direction.left;
        }
        else if (nextTileY > this.tilePosition.y) {
            nextDirection = Direction.down;
        }
        else if (nextTileY < this.tilePosition.y) {
            nextDirection = Direction.up;
        }
        return nextDirection;
    }
}
