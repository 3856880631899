import { Container, Assets, Sprite } from "pixi.js";
export class ImageLayer extends Container {
    static async from(data) {
        let image;
        if (data.image) {
            image = await Assets.load(data.image);
        }
        return new ImageLayer(data, image);
    }
    constructor(data, image) {
        super();
        this.data = data;
        this.visible = data.visible;
        this.alpha = data.opacity;
        if (image) {
            this.addChild(Sprite.from(image));
        }
    }
}
